import { trimStart } from '~/util/trimStart';

export const addOrUpdateUrlParam = (str, key, value) => {
	const urlParamStart = str.indexOf('?');
	let kvp;
	let i;
	let x;

	if (urlParamStart >= 0) {
		// break the url down to the
		kvp = str.substr(urlParamStart).split('&');
		i = kvp.length;

		while (i--) {
			x = kvp[i].split('=');

			if (trimStart(x[0], '?') === key) {
				x[1] = value;
				kvp[i] = x.join('=');
				break;
			}
		}

		if (i < 0) {
			kvp[kvp.length] = [key, value].join('=');
		}

		return str.substring(0, urlParamStart) + kvp.join('&');
	}

	return `${str}?${key}=${value}`;
};
