import { ReadonlyURLSearchParams } from 'next/navigation';

export const removeEmptyQueries = (query: Record<string, any> = {}): void => {
	Object.keys(query).forEach((key) => {
		const queryParam = query[key];
		const isNullOrUndefined = queryParam == null;
		const isEmptyString = queryParam === '';

		if (isNullOrUndefined || isEmptyString) {
			delete query[key];
		}
	});
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const updateRouterQuery = async <T>(query: Partial<T>, shallow: boolean = false): Promise<void> => {
	const params = Object.fromEntries(new URLSearchParams(window.location.search).entries());
	const updatedQuery = { ...params, ...query };

	removeEmptyQueries(updatedQuery);

	window.history.replaceState({}, '', `?${new URLSearchParams(updatedQuery).toString()}`);
	// await Router.replace({ query: updatedQuery }, undefined, { shallow });
};

export const routerToSearchParamsStopGap = (routerQuery: { [property: string]: string }| ReadonlyURLSearchParams | null, queryParam: string) => {
	if (!routerQuery) {
		return null;
	}

	if (routerQuery instanceof ReadonlyURLSearchParams) {
		return routerQuery.get(queryParam);
	}
	return routerQuery[queryParam];
};
