import {
	action, computed, extendObservable, observable, makeObservable,
} from 'mobx';

import { isOnServer, isMobile as globalIsMobile } from '~/global/global.constants';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { addOrUpdateUrlParam } from '~/util/addOrUpdateUrlParam';
import { isEmpty } from '~/util/isEmpty';
import { noop } from '~/util/noop';
import { routerToSearchParamsStopGap } from '~/util/router/router.utils';

const RENDER_MODE = {
	RESPONSIVE: 'RESPONSIVE',
	TABS: 'TABS',
	ACCORDION: 'ACCORDION',
};
const isMobile = (!isOnServer && window.matchMedia('(max-width: 40em)').matches) || globalIsMobile;

class MagicTabccordionStore {
	constructor(data, linkEventStore = {}, linkEventTrack = true, query = {}) {
		makeObservable(this, {
			activeAccordionBtn: observable,
			activeTabContent: observable,
			allowMultipleAccordionsOpen: observable,
			disableAriaRoles: observable,
			disableSmoothScroll: observable,
			isLoaded: observable,
			renderMode: observable,
			responsiveAccordionDefaultClosed: observable,
			tabs: observable,
			tabGroups: observable,
			tabTitleClass: observable,
			tabTitleTag: observable,
			tabTitleWrapperTag: observable,
			wrapperRenderer: observable,
			activeTab: computed,
			hasActiveTabs: computed,
			isAccordion: computed,
			isAccordionOrResponsive: computed,
			isResponsive: computed,
			isTabs: computed,
			isTabsOrResponsive: computed,
			setInitiallyClosed: computed,
			shouldSmoothScroll: computed,
			tabTrackingObject: computed,
			setIsLoaded: action,
			activateTab: action,
			updateTabs: action,
			buildOutTabs: action,
			getTabById: action,
			getTabGroup: action,
			getTabByIndex: action,
			deactivateTab: action,
			addAccordionEventListeners: action,
			addTabEventListeners: action,
			removeTabEventListeners: action,
			giveFocus: action,
			tabGroupLastIndex: action,
			tabGroupIndexPrev: action,
			tabGroupIndexNext: action,
			onAccordionBtnKeydown: action.bound,
			onTabKeydownEvent: action.bound,
			onAnimationEndHandler: action,
			smoothScroll: action,
		});

		if (!data) {
			console.error('Data not found');
			return;
		}

		this.tabSettings = data.tabSettings ? data.tabSettings : {};
		this.tabGroups.replace(data.tabGroups);
		this.tabGroupContainerClass = this.tabSettings.tabGroupContainerClass || null;
		this.renderMode = RENDER_MODE[data.renderMode];
		this.tabTitleTag = this.tabSettings.tabTitleTag;
		this.tabTitleWrapperTag = this.tabSettings.tabTitleWrapperTag;
		this.tabTitleClass = this.tabSettings.tabTitleClass;
		this.tabListClass = data.tabListClass || '';
		this.tabccordionButtonClass = this.tabSettings.tabccordionButtonClass || '';
		this.tabContentClass = this.tabSettings.tabContentClass || '';
		this.tabContentFullHeight = this.tabSettings.tabContentFullHeight || false;
		this.tabContentContainerClass = this.tabSettings.tabContentContainerClass || '';
		this.responsiveAccordionDefaultClosed = data.responsiveAccordionDefaultClosed;
		this.allowMultipleAccordionsOpen = data.allowMultipleAccordionsOpen;
		this.allowMultipleAccordionsOpenInGroup = data.allowMultipleAccordionsOpenInGroup;
		this.allowCloseableTabs = data.allowCloseableTabs || false;
		this.wrapperRenderer = data.wrapperRenderer;
		this.scrollToActiveTab = data.scrollToActiveTab || false;
		this.scrollToActiveTabOffset = data.scrollToActiveTabOffset;
		this.disableAriaRoles = data.disableAriaRoles;
		this.disableSmoothScroll = data.disableSmoothScroll || false;
		this.keepActiveTabOpen = data.keepActiveTabOpen;
		this.updateTabGroupsDisposer = data.updateTabGroupsDisposer || noop;
		this.linkEventStore = linkEventStore;
		this.linkEventTrack = linkEventTrack;
		this.setTabUrlParams = data.setTabUrlParams || false;
		this.queryParams = query;

		this.buildOutTabs(this.tabGroups, data.id, this.queryParams);

		if (!this.hasActiveTabs) {
			this.setIsLoaded(true);
		}
	}

	activeAccordionBtn = {};

	activeTabContent = {};

	allowMultipleAccordionsOpen = '';

	disableAriaRoles = true;

	disableSmoothScroll = false;

	isLoaded = false;

	renderMode = '';

	responsiveAccordionDefaultClosed = '';

	setTabUrlParams = false;

	tabs = [];

	tabGroups = [];

	tabGroupContainerClass = null;

	tabListClass = '';

	tabccordionButtonClass = '';

	tabContentClass = '';

	tabContentFullHeight = false;

	tabContentContainerClass = '';

	tabSettings = {};

	tabTitleClass = '';

	tabTitleTag = '';

	tabTitleWrapperTag = '';

	updateTabGroupsDisposer = noop;

	wrapperRenderer = '';

	get activeTab() {
		let activeTab;

		this.tabGroups.forEach((group) => {
			group.tabs.forEach((tab) => {
				// Check to see if the group has an active tab, important for render mode Tabs and Responsive
				if (tab.isActive) {
					activeTab = tab;
					return false;
				}
				return true;
			});
			return activeTab;
		});
		return activeTab;
	}

	get hasActiveTabs() {
		let activeTab = false;

		this.tabGroups.forEach((group) => {
			group.tabs.forEach((tab) => {
				// Check to see if the group has an active tab, important for render mode Tabs and Responsive
				if (tab.isActive) {
					activeTab = true;
					return false;
				}
				return true;
			});
			return activeTab;
		});

		return activeTab;
	}

	get isAccordion() {
		return this.renderMode === RENDER_MODE.ACCORDION;
	}

	get isAccordionOrResponsive() {
		return this.renderMode === RENDER_MODE.ACCORDION || this.renderMode === RENDER_MODE.RESPONSIVE;
	}

	get isResponsive() {
		return this.renderMode === RENDER_MODE.RESPONSIVE;
	}

	get isTabs() {
		return this.renderMode === RENDER_MODE.TABS;
	}

	get isTabsOrResponsive() {
		return this.renderMode === RENDER_MODE.TABS || this.renderMode === RENDER_MODE.RESPONSIVE;
	}

	// If the render mode is responsive and by default we want accordions closed and the user in on mobile
	get setInitiallyClosed() {
		return this.isAccordionOrResponsive && (this.responsiveAccordionDefaultClosed && isMobile);
	}

	// allow for smooth scrolling if you are an accordion or if you are responsive and the user is on a mobile device
	get shouldSmoothScroll() {
		if (this.disableSmoothScroll || !this.isLoaded || !this.scrollToActiveTab) {
			return false;
		}
		if (this.isAccordion) {
			return true;
		}
		return this.isResponsive && isMobile;
	}

	get tabTrackingObject() {
		if (this.activeTab) {
			return {
				title: this.activeTab.title,
			};
		}

		return null;
	}

	setIsLoaded(isLoaded) {
		this.isLoaded = isLoaded;
	}

	updateSelectedTabUrlParams(selectedTabGroup, selectedTab) {
		let newUrl = '';
		if (!isOnServer && typeof window !== 'undefined') {
			const {
				history,
				location: {
					origin,
					pathname = '',
				} = {},
			} = window;
			// by default if the first tabGroup and tab are active do not need to add params
			if (selectedTabGroup.index === 0 && selectedTab.index === 0) {
				history.replaceState({}, '', `${origin}${pathname}`);
			} else {
				newUrl = addOrUpdateUrlParam(`${origin}${pathname}`, 'tabGroup', selectedTabGroup.groupName);
				newUrl = addOrUpdateUrlParam(newUrl, 'tabId', selectedTab.id);
				history.replaceState({}, '', newUrl);
			}
		}
	}

	activateTab(tabModel, isAccordion = false) {
		const selectedGroup = this.getTabGroup(tabModel.tabGroup);
		const selectedTab = this.getTabByIndex(tabModel.tabGroup, tabModel.index);

		const { isValid = true } = tabModel;

		if (!isValid) {
			return;
		}

		// If a the user clicked on an accordion and it's already open, close it
		if (!this.keepActiveTabOpen && (isAccordion || this.allowCloseableTabs) && tabModel.isActive) {
			this.deactivateTab(selectedTab);
			return;
		}

		// Close all tabs in the selected group unless it's allowed
		if (!this.allowMultipleAccordionsOpenInGroup) {
			selectedGroup.tabs.forEach((tab) => {
				this.deactivateTab(tab);
			});
		}

		selectedTab.isActive = true;

		if (this.setTabUrlParams) {
			this.updateSelectedTabUrlParams(selectedGroup, selectedTab);
		}

		// If allowMultipleAccordionsOpen is false and an accordion was clicked, close all other accordions on the page
		if (!this.allowMultipleAccordionsOpen && isAccordion) {
			this.tabGroups.forEach((group) => {
				if (group.groupName !== tabModel.tabGroup) {
					group.tabs.forEach((tab) => {
						if (tab.isActive) {
							this.deactivateTab(tab);
						}
					});
				}
			});
		}
		if (typeof selectedTab.onTabActivate === 'function') {
			selectedTab.onTabActivate();
		}
	}

	updateTabs(tabGroups, id) {
		this.tabGroups.replace(tabGroups);
		this.tabs = [];
		this.buildOutTabs(this.tabGroups, id);
	}

	buildOutTabs(tabGroups, id, queryParams) {
		tabGroups.forEach((group, index) => {
			extendObservable(group, {
				// Give each tabGroup a groupName based off of the id and index
				groupName: `${id}-group${index}`,
				index,
			});
			let noActiveTab = true;

			group.tabs.forEach((tab, i) => {
				tab.index = i;
				// If the tab does not come with an isActive key add it, default false
				if (tab.isActive === undefined) {
					extendObservable(tab, {
						isActive: false,
					});
				}
				// add the tab group name to each tab, necessary to activate and deactivate tabs
				extendObservable(tab, {
					tabGroup: `${id}-group${index}`,
				});
				// Check to see if the group has an active tab, important for render mode Tabs and Responsive
				if (noActiveTab && tab.isActive) {
					noActiveTab = false;
				}
				this.tabs.push(tab);
			});

			if (routerToSearchParamsStopGap(queryParams, 'tabGroup') && routerToSearchParamsStopGap(queryParams, 'tabId')) {
				const selectedGroup = this.getTabGroup(routerToSearchParamsStopGap(queryParams, 'tabGroup'));
				const selectedTab = selectedGroup?.tabs?.find?.(t => t.id === routerToSearchParamsStopGap(queryParams, 'tabId'));

				if (selectedTab) {
					this.activateTab(selectedTab);
					noActiveTab = false;
				}
			}

			// If hasActive == false set the first tab in the group to active
			if (this.isTabsOrResponsive && noActiveTab && !this.allowCloseableTabs) {
				const selectedTab = this.getTabByIndex(group.groupName, 0);
				if (selectedTab) {
					this.activateTab(selectedTab);
				}
			}
		});
	}

	// function to open a tab or accordion by url params
	getTabById(tab) {
		if (isEmpty(tab.tabGroup) || isEmpty(tab.tabId)) {
			return false;
		}
		// make sure the tabGroup exists
		const selectedGroup = this.getTabGroup(tab.tabGroup);
		if (!selectedGroup) {
			return false;
		}
		// make sure the tab with id exists
		const selectedTab = selectedGroup?.tabs?.find?.(t => t.id === tab.tabId);
		if (!selectedTab) {
			return false;
		}
		extendObservable(selectedTab, {
			responsiveAccordionDefaultClosed: this.responsiveAccordionDefaultClosed,
		});

		return selectedTab;
	}

	getTabGroup(tabGroup) {
		return this?.tabGroups?.find?.(tg => tg.groupName === tabGroup);
	}

	getTabByIndex(tabGroup, index) {
		const selectedGroup = this?.tabGroups?.find?.(tg => tg.groupName === tabGroup);
		return selectedGroup?.tabs?.find?.(tab => tab.index === index);
	}

	deactivateTab(tab) {
		tab.isActive = false;
	}

	deactivateAllTabs() {
		this.tabGroups.forEach((tabGroup) => {
			tabGroup.tabs.forEach((tab) => {
				this.deactivateTab(tab);
			});
		});
	}

	trackInteration(tab) {
		if (!this.linkEventTrack) {
			return null;
		}

		if (!this.linkEventStore.trackLinkEvent) {
			console.error('MagicTabccordion trackLinkEvent not found');
			return null;
		}

		const { tabGroup } = tab;
		const count = this.getTabGroup(tabGroup).tabs.length || null;
		const compName = tab.trLinkEventCompName || tab?.filterModel?.title || tab?.selectorValueModel?.title || tab?.selectedSelector?.label || tab?.title || null;
		const compType = tab.trLinkEventCompType || 'tabccordion';
		const eventName = tab.trLinkEventName || 'open';
		const eventType = tab.trLinkEventType || 'SITE_ACTION';

		if (window.eventDataLayer && this.linkEventStore) {
			const linkEventTrackingData = {
				trLinkEventCompName: compName,
				trLinkEventCompType: compType,
				trLinkEventName: eventName,
				trLinkEventType: LinkEventTypes[eventType],
			};
			if (count > 1) {
				linkEventTrackingData.trLinkEventCompPosition = `${tab.index + 1}:${count}`;
			}

			if (tab.trLinkEventCompPosition) {
				linkEventTrackingData.trLinkEventCompPosition = tab.trLinkEventCompPosition;
			}

			this.linkEventStore.trackLinkEvent(linkEventTrackingData);
		}
		return true;
	}

	// Keyboard accessibility
	keysList = {
		end: 35,
		home: 36,
		left: 37,
		up: 38,
		right: 39,
		down: 40,
		delete: 46,
		enter: 13,
		space: 32,
	};

	addAccordionEventListeners(elem, tab) {
		if (!isOnServer) {
			elem.addEventListener('keydown', this.onAccordionBtnKeydown);
		}
		elem.tabGroup = tab.tabGroup;
		elem.index = tab.index;
	}

	addTabEventListeners(elem, tab) {
		if (!isOnServer) {
			elem.addEventListener('keydown', this.onTabKeydownEvent);
		}
		elem.tabGroup = tab.tabGroup;
		elem.index = tab.index;
	}

	removeTabEventListeners(elem) {
		elem.removeEventListener('keydown', this.onTabKeydownEvent);
	}

	giveFocus(tabGroup, index) {
		const selector = `[aria-controls="${tabGroup}-tabccordion-content${index}"]`;
		const elements = document.querySelectorAll(selector);
		Array.prototype.forEach.call(elements, function (elem) {
			elem.focus();
		});
	}

	tabGroupLastIndex(group) {
		const selectedGroup = this.getTabGroup(group);
		return selectedGroup.tabs.length - 1;
	}

	tabGroupIndexPrev(group, index) {
		const selectedGroup = this.getTabGroup(group);
		let prev = index - 1;
		if (prev < 0) {
			prev = selectedGroup.tabs.length - 1;
		}
		return prev;
	}

	tabGroupIndexNext(tabGroup, index) {
		const selectedGroup = this.getTabGroup(tabGroup);
		let next = index + 1;
		if (next > selectedGroup.tabs.length - 1) {
			next = 0;
		}
		return next;
	}

	onAccordionBtnKeydown(event) {
		if (!event) {
			return;
		}
		const key = event.keyCode;
		let { index } = event.target;
		const { tabGroup } = event.target;
		switch (key) {
			case this.keysList.end:
				event.preventDefault();
				index = this.tabGroupLastIndex(tabGroup, index);
				this.giveFocus(tabGroup, index);
				break;
			case this.keysList.home:
				event.preventDefault();
				index = 0;
				this.giveFocus(tabGroup, index);
				break;
			case this.keysList.up:
				index = this.tabGroupIndexPrev(tabGroup, index);
				this.giveFocus(tabGroup, index);
				break;
			case this.keysList.down:
				index = this.tabGroupIndexNext(tabGroup, index);
				this.giveFocus(tabGroup, index);
				break;
			default:
				break;
		}
	}

	onTabKeydownEvent(event) {
		if (!event) {
			return;
		}
		const key = event.keyCode;
		let { index } = event.target;
		const { tabGroup } = event.target;
		switch (key) {
			case this.keysList.end:
				event.preventDefault();
				index = this.tabGroupLastIndex(tabGroup, index);
				this.activateTab({ tabGroup, index });
				this.giveFocus(tabGroup, index);
				break;
			case this.keysList.home:
				event.preventDefault();
				index = 0;
				this.activateTab({ tabGroup, index });
				this.giveFocus(tabGroup, index);
				break;
			case this.keysList.left:
				index = this.tabGroupIndexPrev(tabGroup, index);
				this.activateTab({ tabGroup, index });
				this.giveFocus(tabGroup, index);
				break;
			case this.keysList.right:
				index = this.tabGroupIndexNext(tabGroup, index);
				this.activateTab({ tabGroup, index });
				this.giveFocus(tabGroup, index);
				break;
			default:
				break;
		}
	}

	onAnimationEndHandler(accordionBtn) {
		if (this.shouldSmoothScroll) {
			this.smoothScroll(accordionBtn, 200);
		}
		if (!this.isLoaded) {
			this.setIsLoaded(true);
		}
	}

	smoothScroll(element) {
		if (this.scrollToActiveTab === false) {
			return;
		}

		const { scrollToActiveTabOffset = 0 } = this;
		const targetElementTop = window.pageYOffset + element.getBoundingClientRect().top;
		const mobileHeaderOffset = 58;
		const startingY = window.pageYOffset;
		const elementY = isMobile
			? targetElementTop - scrollToActiveTabOffset - mobileHeaderOffset
			: targetElementTop - scrollToActiveTabOffset;
		const diff = elementY - startingY;

		window.scrollTo({
			top: startingY + diff,
			behavior: 'smooth',
		});
	}
}

export { MagicTabccordionStore };
